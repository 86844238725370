// import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"

// 🎨 UI
import { Heading, Box, Text, Spacer, Flex, Image } from "@chakra-ui/react"
import SliderButton from "../../components/Buttons/SliderButton"

// 🚀 Transition
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import TransitionButton from "../../components/Buttons/TransitionButton"
import { moveCameraToPosition } from "../../components/Animation/CameraAnimation"

// <-🖼 ->Slider
import TOCSliderButton from "../Buttons/TOCSliderButton"
import { insertStyledHTMLContent } from "../../tools/helper"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import "./ProjektzieleSlider.css"

const ProjetzieleSlider = props => {
  // <-🖼 ->Slider
  const SLIDERINFO = useSelector(
    state => state.projektzieleSlider,
    shallowEqual
  )

  const sendActionToStore = props.sendActionToStore

  // const [location, setLocation] = useState(
  //   props.slides[SLIDERINFO.current]?.acf_scene?.location?.acf_location
  //     ?.location
  // )

  // * Set slider to unmount by props or click on button
  // const [unmount, setUnmount] = useState(props.unmountSlider)

  // useEffect(() => {
  //   setUnmount(props.unmountSlider)
  // }, [props.unmountSlider])

  // useEffect(() => {
  //   if (!unmount) {
  //     if (props.slides[SLIDERINFO.current] !== undefined) {
  //       const loc =
  //         props.slides[SLIDERINFO.current].acf_scene?.location?.acf_location
  //           .location

  //       if (loc) {
  //         console.log("move to", loc)

  //         moveCameraToPosition(sendActionToStore, loc.x - 2, loc.y + 5)
  //         setLocation(loc)
  //       }
  //     }
  //   }
  //   return () => {
  //     setUnmount(true)
  //   }
  // }, [SLIDERINFO, props.slides, sendActionToStore, unmount])

  const moveSlider = useCallback(() => {
    if (props.slides[SLIDERINFO.current] !== undefined) {
      const loc =
        props.slides[SLIDERINFO.current].acf_scene?.location?.acf_location
          .location

      if (loc) {
        console.log("move to", loc)

        moveCameraToPosition(sendActionToStore, loc.x - 2, loc.y + 5)
        // setLocation(loc)
      }
    }
  }, [SLIDERINFO, props.slides, sendActionToStore])

  return (
    <Box
      py={["24px", "32px", "32px", "64px"]}
      width="100%"
      position="relative"
      background="white"
      maxWidth="700px"
      borderRadius="30px"
      zIndex="1"
      // shadow="subtle"
      className="elevation_sm"
    >
      {/* TITEL */}
      <Flex px={["24px", "32px", "32px", "64px"]}>
        <Heading as="h2" size="md" color="modeGray.500">
          {/* {props.settings.textHeadlineProjektzieleSlider}:{" "} */}
          {
            props.slides[0]?.acf_projektziel_content.groupLeft
              .relationMobilitaetsschwerpunkt.title
          }
        </Heading>
        <Spacer />
        <Text fontWeight="bold">
          {SLIDERINFO.current + 1}/{props.slides.length}
        </Text>
      </Flex>

      {/* Slider */}
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={false}
        dynamicHeight={true}
        useKeyboardArrows={true}
        renderThumbs={false}
        selectedItem={SLIDERINFO.current}
        emulateTouch={false}
        swipeable={false}
      >
        {props.slides.map((p, index) => {
          return (
            <Box key={index}>
              <Heading
                px={["24px", "32px", "32px", "64px"]}
                as="h3"
                align="left"
                size={"xl"}
                mt="16px"
                mb="32px"
              >
                {p.title}
              </Heading>
              <Box
                display={
                  p.acf_projektziel_content.groupLeft.imageFeaturedImage
                    ?.localFile.publicURL
                    ? "block"
                    : "none"
                }
                overflow="hidden"
                height="234px"
                mb="32px"
              >
                <Image
                  src={
                    p.acf_projektziel_content.groupLeft.imageFeaturedImage
                      ?.localFile.childImageSharp.fluid.src
                  }
                  srcSet={
                    p.acf_projektziel_content.groupLeft.imageFeaturedImage
                      ?.localFile.childImageSharp.fluid.srcSet
                  }
                  alt={
                    p.acf_projektziel_content.groupLeft.imageFeaturedImage
                      ?.altText
                  }
                />
              </Box>
              <Text
                px={["24px", "32px", "32px", "64px"]}
                className="sliderParagraphs text-left pb-3"
                align="left"
                dangerouslySetInnerHTML={insertStyledHTMLContent(
                  p.acf_projektziel_card.textSummary
                )}
              />
            </Box>
          )
        })}
      </Carousel>

      <SliderButton
        left
        currentSliderIndex={SLIDERINFO.current}
        slidesAmount={props.slides.length}
        sendActionToStore={props.sendActionToStore}
        channel={"projektzieleSlider"}
        onClick={moveSlider}
      />

      <SliderButton
        right
        currentSliderIndex={SLIDERINFO.current}
        slidesAmount={props.slides.length}
        sendActionToStore={props.sendActionToStore}
        channel={"projektzieleSlider"}
        onClick={moveSlider}
      />

      <TOCSliderButton
        sendActionToStore={props.sendActionToStore}
        slides={props.slides}
        currentSlide={SLIDERINFO.current}
        channel={"projektzieleSlider"}
        title={props.settings.textHeadlineProjektzieleSlider}
      />

      {/* Buttons */}
      <Flex
        pos="absolute"
        width="100%"
        justify="flex-end"
        left="0px"
        bottom="-28px"
      >
        {/* TODO: API: Dynamischer Link zur Unterseite? GenURL einbauen */}
        <TransitionButton
          text={props.settings.textButtonProjektzieleSlider}
          TLclassName="mr-7"
          link={
            // LANG +
            // "/" +
            // props.currentPath +
            // "/" +
            props.slides[SLIDERINFO.current]?.slug
          }
          targetPageInAnimation="right"
          onClick={() => {
            // setUnmount(true)
            moveCurrentPageOut(props.sendActionToStore, "left")
          }}
        />
      </Flex>
    </Box>
  )
}

ProjetzieleSlider.propTypes = {}

export default ProjetzieleSlider
